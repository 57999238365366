import React, { useEffect } from 'react';
import { auth } from '../../src/services/firebase';

const UserProfilePage = () => {
  useEffect(() => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const { email } = currentUser;
      console.log('User Email:', email);
    } else {
      console.log('No user is logged in');
    }
  }, []);

  const getUserNameFromEmail = (email) => {
    // Assuming email is in the format "name@domain.com"
    const atIndex = email.indexOf('@');
    return atIndex !== -1 ? email.slice(0, atIndex) : email;
  };

  const userName = auth.currentUser ? getUserNameFromEmail(auth.currentUser.email) : '';

  return (
    <div>
      <h1>Welcome Back {userName}</h1>
      {/* Your UserProfilePage content goes here */}
    </div>
  );
};

export default UserProfilePage;

