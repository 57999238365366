import { initializeApp } from 'firebase/app';
import { signOut, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase } from 'firebase/auth';
import { getFirestore, collection, addDoc, doc, getDoc } from 'firebase/firestore';  // Import Firestore functions
import { Await } from 'react-router-dom';


const firebaseConfig = {
  // Your Firebase configuration

  apiKey: "AIzaSyCRfVAAJe4-7SeELUz1HoyF0n4gSZGLbWs",
  authDomain: "agraweb-5613b.firebaseapp.com",
  projectId: "agraweb-5613b",
  storageBucket: "agraweb-5613b.appspot.com",
  messagingSenderId: "62867470788",
  appId: "1:62867470788:web:6e04a399bcb9eeacba0c91" 
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const signUpWithEmailAndPassword = async (email, password, isAdmin = false) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Add user details to Firestore
    await createUserInFirestore(user.uid, email, isAdmin);

    return user;
  } catch (error) {
    throw error;
  }
};

const createUserInFirestore = async (uid, email, isAdmin) => {
  try {
    const userRef = collection(db, 'users');
    await addDoc(userRef, {
      uid,
      email,
      status: 'pending-approval',
      adminStatus: isAdmin,
    });
  } catch (error) {
    throw error;
  }
};

//sign out users from the application
const firebaseSignOut = async () =>{

  try {
    await signOut(auth)
    console.log("User Signed Out")
  } catch (error) {
    console.log("Error During Sign Out", error)
    throw error

    
  }
}

export const signInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPasswordFirebase(auth, email, password);
    const user = userCredential.user;
    return user;
  } catch (error) {
    throw error;
  }
};

export { db, auth, doc, getDoc,getFirestore, firebaseSignOut };  // Export doc and getDoc from Firestore
export default app;
