import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../../src/moa_logo.jpeg';
import { NavLink, Link, useNavigate } from "react-router-dom";

const SiteMenu = () => {
    const logoStyle = {
        maxHeight: '3rem', // Adjust as needed
        marginRight: '1rem',
      };


  return (
   <>
  <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid">
  <a className="navbar-brand" href="#" style={{ marginLeft: '15px' }}>
    <img src={logo} alt="Logo" style={logoStyle} />
    </a>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link" aria-current="page" href="/">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/about">
           About
          </a>
        </li>
   
   
        
        
      </ul>

    
      <div>
  <a href='/login' className='btn btn-success' style={{ marginRight: '10px' }}>
    Login
  </a>

  <a href='/signup' className='btn btn-primary'>
    Create Account
  </a>
</div>


     
    </div>
  </div>
</nav>
 
   
   
   
   </>
  )
}

export default SiteMenu