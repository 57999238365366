
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../../src/services/firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//dashboardChart2
//user can update amount and needs to show time when the top up happened


const UserDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const userDocRef = auth.currentUser.uid;

          try {
            const querySnapshot = await getDocs(collection(db, 'users'));
            const usersData = [];

            querySnapshot.forEach((doc) => {
              usersData.push(doc.data());
            });

            //console.log("All Users-->", usersData);

            // Loop through the array and match the uid of the logged-in user
            const loggedInUser = usersData.find((userData) => userData.uid === userDocRef);

            if (loggedInUser) {
              // Log the value of the "status" for the logged-in user
              console.log("User is ", loggedInUser.status);

              // Show a toast message
              toast(`Logged-in User Status: ${loggedInUser.status}`, {
                position: 'top-center',
                autoClose: 5000, // Close the toast after 5 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              // You can perform actions based on the status here
              // if (loggedInUser.status === 'pending-approval') {
              //   navigate('/');
              // } else if (loggedInUser.status === 'approved' && !loggedInUser.adminStatus) {
              //   navigate('/analytics');


              // } else if (loggedInUser.status === 'approved' && loggedInUser.adminStatus) {
              //   navigate('/admin');
              // }
              if (loggedInUser.status === 'pending-approval') {
               navigate('/overall-spend')
              } else if (loggedInUser.status === 'approved' && !loggedInUser.adminStatus) {
                navigate('/overall-spend')
              }
              else if (loggedInUser.status === 'approved' && loggedInUser.adminStatus) {
                  navigate('/a/admin');
                }

            } else {
              console.log('Logged-in user not found in the array.');
            }
          } catch (error) {
            console.error('Error fetching users:', error.message);
          }
        } else {
          console.log('No user is logged in');
        }
      } catch (error) {
        console.error('Error checking user status:', error.message);
      } finally {
        setLoading(false);
      }
    });

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, [navigate, setLoading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <div>Welcome Back</div>;
};

export default UserDashboard;
