import React from 'react'
import '../assets/css/bootstrap.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../src/moa_logo.jpeg';
import SiteMenu from '../components/nav/SiteMenu';
import Hero from '../components/nav/Hero';
import banner from '../../src/assets/images/icsp.jpg';
import Footer from './Footer';

const About = () => {

    const logoStyle = {
        maxHeight: '3rem', // Adjust as needed
        marginRight: '1rem',
      };

  return (
    <>

    <SiteMenu/>
  

    <div className='container'>
        <div className='row'> 
           
   

            <div className='col-sm-6'> <br/> <br/>
            <p style={{textAlign:'left'}} >About Us</p> <br/>
     
     <h3 class="" style={{textAlign:'left'}}>
    
     Agricultural Sector Institutional Capacity Strengthening Plan (ICSP) Monitoring Dashboard
 </h3>
 <p class="lead" style={{textAlign:'left'}}>
 The Agricultural Sector Institutional Capacity Strengthening Plan (ICSP) provides a five-year framework for capacity development in the sector. The ICSP addresses all the systemic capacity challenges hindering agricultural sector development and transformation.

This dashboard aims to map and monitor the key investments made in skills and capacity strengthening in the sector in line with the ICSP and will look at all the programs being conducted by the various actors at an individual, institutional and systemic level.

This will enable the sector have visibility of the capacity strengthening initiatives currently being implemented in the sectors by various actors and also create a monitoring mechanism for the same.
 </p>
 
 
       <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-md-3">
        
 
      
         
       
    
    
     </div>  


            </div> 

            <div className='col-sm-4'> <br/>

            <img src={banner} alt="Logo"  />
            </div>

        </div>
    </div>

  
  <Footer/>
  
  </>
  
  )
}

export default About;