import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from '../../../src/context/AuthContext';
import { auth, db, signInWithEmailAndPassword } from '../../../src/services/firebase';
import { getFirestore, doc, getDoc } from '../../../src/services/firebase';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteMenu from '../nav/SiteMenu';
import Footer from '../../views/Footer';






const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     // Call Firebase login function
  //     await signInWithEmailAndPassword(formData.email, formData.password);
  
  //     // Get user document snapshot
  //     const userDocRef = doc(db, 'users', auth.currentUser.uid);
  //     const userDocSnapshot = await getDoc(userDocRef);
  
  //     // Check if user document exists
  //     if (userDocSnapshot.exists()) {

  //       console.log("Check the snapshot", userDocSnapshot)

  //       const { status, adminStatus } = userDocSnapshot.data();
  
  //       // Check user status
  //       if (status === 'pending-approval') {
  //         // Notify user about pending approval (you can redirect or show a message)
  //         console.log('User account is pending approval.');
  //       } else if (status === 'approved' && adminStatus) {
  //         // Redirect to admin page
  //         navigate("/admin");
  //       } else if (status === 'approved' && !adminStatus) {
  //         // Redirect to user profile page
  //         navigate("/user-profile");
  //       } else {
  //         // Handle other cases as needed
  //       }
  //     } else {
  //       // Handle case where user document does not exist
  //       console.log('User document does not exist.');
  //     }
  //   } catch (error) {
  //     // Handle login error (display error message or toast)
  //     console.error('Login error:', error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call Firebase login function
      await signInWithEmailAndPassword(formData.email, formData.password);

      localStorage.setItem("email", formData.email)

      console.log("emails is -->", formData.email)
      console.log("password is -->", formData.password)

      toast.success('Login Success!', {
        position: 'top-right',
        className: 'custom-toast',
        autoClose: 3000, // Automatically close after 3 seconds
      });

      // Redirect to the user dashboard or wherever you want
     navigate("/user-dashboard");
      //navigate("/admin");
    } catch (error) {
      // Handle login error (display error message or toast)
     
      const errorCode = error.code || 'unknown';

      toast.error(`Login Error: ${errorCode}`, {
        position: 'top-right',
      });

    }
  };
  return (
<>
<SiteMenu/> 

<section className="vh-100 d-flex align-items-center justify-content-center">
  <div className="container">
    <div className="row">
      <div className="col-md-6 offset-md-3 shadow-lg p-5 bg-white rounded">
        <form style={{ width: "100%" }}  onSubmit={handleSubmit}> 

        <ToastContainer position="top-right" autoClose={3000} />
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" required />

            
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input type="password" name="password" value={formData.password} onChange={handleChange} className="form-control" required />
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            Login
          </button>
        </form>
        <div className="mt-3 text-center">
          <a href="#!" className="text-muted">
            Forgot password?
          </a>
        </div>
        <div className="mt-3 text-center">
          Don't have an account?{" "}
          <a href="/signup" className="link-info">
            Register here
          </a>
        </div>
      </div>
    </div>
  </div>
</section>



</>
    





  );
};

export default Login;
