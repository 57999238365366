import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; 
import { Card, Container, Modal } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import '../../../src/App.css'

const cleanUpAttributeNames = (data) => {
  return data.map(project => {
    const cleanedAttributes = {};
    // Clean up attribute names by removing spaces
    Object.keys(project.attributes).forEach(attribute => {
      const cleanedAttributeName = attribute.replace(/\s+/g, '');
      cleanedAttributes[cleanedAttributeName] = project.attributes[attribute];
    });

    return {
      ...project,
      attributes: cleanedAttributes,
    };
  });
};

const calculateProjectDuration = (project) => {
  const projectStart = new Date(project.attributes.ProjectStart);
  const projectEnd = new Date(project.attributes.ProjectEnd);
  const durationInDays = Math.floor((projectEnd - projectStart) / (1000 * 60 * 60 * 24));
  return durationInDays;
};

const ProducerCharts = () => {
  const [apiData, setApiData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [developmentPartners, setDevelopmentPartners] = useState([]);
  const [selectedDevelopmentPartner, setSelectedDevelopmentPartner] = useState(null);
  const [projectsByNumbers, setProjectsByNumbers] = useState([]);
  const [projectsByFunding, setProjectsByFunding] = useState([]);
  const [projectbeneficiaries, setProjectBeneficiaries] = useState([]);

  const [totalSpentByPartner , setTotalSpentByPartner] = useState();

  

  useEffect(() => {
    console.log("Attempting to fetch data...");
    fetch('https://agra-failover-env2.vercel.app/api/producer-associations')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('API Data-->:', data);
        const cleanedData = cleanUpAttributeNames(data.data || []);
        // Log each project in cleanedData line by line
        cleanedData.forEach(project => {
          console.log("Project ID:", project.id);
          console.log("Project Name:", project.attributes.Organization);
          // console.log("Implementing Agency:", project.attributes.ImplementingAgency);
          // console.log("Thematic Areas:", project.attributes.ThematicAreas);
          // console.log("County:", project.attributes.County);
          // console.log("Project Start:", project.attributes.ProjectStart);
          // console.log("Project End:", project.attributes.ProjectEnd);
        });

        setApiData(cleanedData);

        // Extract unique Development Partners
        const uniqueDevelopmentPartners = [...new Set(cleanedData.map(project => project.attributes.Organization))];
        setDevelopmentPartners(uniqueDevelopmentPartners);

        let totals = []
        let fundings = []

        // loop to filter project then calculate totals
        uniqueDevelopmentPartners.forEach((partner)=>{
            
            const partner_data = cleanedData.filter((data)=> data.attributes.DevelopmentPartner === partner)
            const count = partner_data.length
            const partner_total = partner_data.reduce((total, project) => total + parseFloat(project.attributes.Budget), 0)

            totals.push({"name": partner, "count" : count})

            fundings.push({"name": partner, "fundingTotal" : partner_total})


        })
        setProjectsByFunding(fundings)
        setProjectsByNumbers(totals)     
     console.log("Projects by Numbers is", totals);
        //Extract beneficiaries
  
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (selectedDevelopmentPartner) {
      calculateTotalBudgetPerPartner(apiData);
      console.log("Selected is -->", selectedDevelopmentPartner);
      // Add other logic related to selectedDevelopmentPartner here

      //benefificiaries
      const beneficiaries = apiData.map(project => project.attributes.Beneficiaries || '');
      setProjectBeneficiaries(beneficiaries)
        console.log("Hawa ndio Beneficiaries", beneficiaries);
    }
  }, [selectedDevelopmentPartner, apiData]);

  const handleDevelopmentPartnerChange = (event) => {
    setSelectedDevelopmentPartner(event.target.value);    
  };

   // Filter projects based on the selected Development Partner
   const filteredProjects = apiData.filter(project => project.attributes.DevelopmentPartner === selectedDevelopmentPartner);

   console.log("Filtered", filteredProjects);

   const formatted_table_budget = filteredProjects.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD', // Customize currency if needed
  });

  console.log("Filtered", filteredProjects);



   //console.log("Bene is -->", beneficiaries[0]);

   //console.log("Filtered Projects", filteredProjects)
  // Extract project names and durations from the cleaned API data
  const projectNames = apiData.map(project => project.attributes.ProjectName || '');
  const projectBudgets = apiData.map(project => project.attributes.Budget || 0);
  const projectDurations = apiData.map(project => calculateProjectDuration(project));
  const newChartData = {
    labels: projectNames,
    datasets: [
      {
        label: 'Total Project Funds in (USD)', // Updated the label
        data: projectBudgets, // Updated to use projectBudgets
        backgroundColor: "blue",
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        borderColor: "pink", 
      },
    ],
    
  };

  const handleDatasetClick = (event, elements) => {
    if (elements.length > 0) {
      const selectedDatasetIndex = elements[0].index;
      const selectedProjectData = apiData[selectedDatasetIndex].attributes;
      setSelectedProject(selectedProjectData);
    }
  };

  const newChartOptions = {
    scales: {
      x: {
        type: 'category', // Explicitly set the x-axis scale type
        position: 'bottom',
      },
      y: {
        beginAtZero: true,
        stepSize: 500000,
        labels: {
          // Consider adding units for clarity (e.g., "USD")
        },
      },
    },
    onClick: handleDatasetClick,
    indexAxis: 'x', // Ensure this is present if using Chart.js 3.7.0 or later
    elements: {
      bar: {
        barPercentage: 0.9, // Adjust bar width to 80% for better visual appeal
        backgroundColor: "#f6ad55", // Consider a more visually appealing color
        hoverBackgroundColor: "#ffa726", // Add hover effect for visual feedback
        borderColor: "#f6ad55", // Match border color to background
        borderWidth: 5,
      },
    },
  };


  // Calculate project counts based on the selected development partner
const projectCounts = developmentPartners.map(partner =>
  filteredProjects.filter(project =>
    project.attributes.DevelopmentPartner === partner
  ).length
);

const chartData = {
 //labels: ["World Bank", "AGRA"],
 labels: projectsByNumbers.map((item) => item.name ),
  datasets: [
    {
        label: 'Number of Projects',
        data: projectsByNumbers.map((item) => item.count ),
       // data: projectCounts,
       backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ], // Example jungle green hex code
        borderColor: 'rgba(143, 188, 143, 1)', // Use the same green for border
        borderWidth: 1, // Set desired border width
    },
],

};


  const chartOptions = {
    scales: {
      x: { // Adjust as needed
        type: 'category',
        position: 'bottom',
      },
      y: {
        beginAtZero: true,
        stepSize: 1,
        labels: {
          
        },
      },
    },
  };





 //show total budget per Partner
 const calculateTotalBudgetPerPartner = (data ) => {
  //console.log("ona", data)
              if (data.length > 0) {
                  const totalBudget = data.filter((item )=> item.attributes.DevelopmentPartner=== selectedDevelopmentPartner).reduce((total, project) => total + parseFloat(project.attributes.Budget), 0);
                  
                  const formattedBudget = totalBudget.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'KES', // Customize currency if needed
                  });
                  setTotalSpentByPartner(formattedBudget)


                  //console.log('Total Budget log this--->:', totalBudget);
                } else {
                  console.log('Total Budget: 0');
                }   
            };



              // State for controlling the modal visibility
  const [showModal, setShowModal] = useState(false);

  // State to store the clicked chart data
  const [clickedChartData, setClickedChartData] = useState(null);

  // Function to handle the chart click event
  const handleChartClick = (elements) => {
    if (elements.length > 0) {
      const clickedIndex = elements[0]._index;
      const clickedData = projectsByNumbers[clickedIndex];
      setClickedChartData(clickedData);
      setShowModal(true);
    }
  };

  // Modal close handler
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Render the modal with chart data
  const renderModal = () => (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Chart Data Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Customize the modal body based on your chart data */}
        <p>{clickedChartData && `Label: ${clickedChartData.name}`}</p>
        <p>{clickedChartData && `Count: ${clickedChartData.count}`}</p>
        {/* Add more details as needed */}
      </Modal.Body>
    </Modal>
  );

  //Download projects 
  const downloadProjectsData = () => {
    const worksheet = XLSX.utils.json_to_sheet(projectsByNumbers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ProjectsData');
    XLSX.writeFile(workbook, 'projects_data.xlsx');
  };


  return (

    <>

    <div>

         

    <Bar data={chartData} options={{ onClick: handleChartClick }} />

    <div className="mb-3">
  <label htmlFor="developmentPartner" className="form-label">
  Select Producer Association
  </label>
  <select
    id="developmentPartner"
    className="form-select"
    onChange={handleDevelopmentPartnerChange}
    value={selectedDevelopmentPartner}
  >
    <option value="">All</option>
    {developmentPartners.map(partner => (
      <option key={partner} value={partner}>
        {partner}
      </option>
    ))}
  </select>
</div>


   

    <div className="col-md-12 col-sm-12 col-xs-2" style={{ backgroundColor: '#086c14', padding: '10px', color: '#fff' }}>
  <p style={{ margin: '0', fontSize: '12px', fontWeight: 'normal' }}>
    Total Spend <strong>{selectedDevelopmentPartner}</strong>   <strong> {totalSpentByPartner} </strong> 
  </p>
</div> <br/>


<div 
  className="col-md-12 col-sm-12 col-xs-2" 
  style={{ backgroundColor: '#086c14', padding: '10px', color: '#fff', cursor: 'pointer' }}
  onClick={downloadProjectsData}
>
  <p style={{ margin: '0', fontSize: '12px', fontWeight: 'normal' }}>
    Download Projects Summary
  
  
    Data
  </p>
</div> <br/>


 
 


  <div > <br/>
    {/* Render project details here */}



    <div className="card mt-8">
  <div className="table-responsive">
    <table className="table table-striped table-bordered">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Project Name</th>
          <th scope="col">Implementing Agency</th>
          <th scope="col">Thematic Areas</th>
          <th scope="col">Beneficiaries</th>
          <th scope="col">County</th>
          <th scope="col">Project Start</th>
          <th scope="col">Project End</th>
          <th scope="col">Spend (KES)</th>
        </tr>
      </thead>
      <tbody>
        {filteredProjects.map(project => (
          <tr key={project.id}>
            <td>{project.attributes.ProjectName}</td>
            <td>{project.attributes.ImplementingAgency}</td>
            <td>{project.attributes.ThematicAreas}</td>
            <td>{project.attributes.Beneficiaries}  </td>
            <td>{project.attributes.County}</td>
            <td>{project.attributes.ProjectStart}</td>
            <td>{project.attributes.ProjectEnd}</td>
            <td>{project.attributes.Budget.toLocaleString('en-US', {
  style: 'currency',
  currency: 'KES', // Customize currency if needed
})}</td>

          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>


  </div>




    </div>

    </>
  
  );
};
export default ProducerCharts;



//ProducerCharts