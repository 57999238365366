import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css'
import { Bar } from 'react-chartjs-2';

const OverallSpendChart = () => {
  const [data, setData] = useState([]);
  const [spendData, setSpendData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching data from the first API
        const response = await fetch('https://agra-failover-env2.vercel.app/api/total');
        const result = await response.json();

        //console.log("Log My Data--->", result.spend);

        if (!result || !result.data || !Array.isArray(result.data)) {
          console.error('Invalid data format');
          return;
        }

        setData(result.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const chartData = {
    labels: data.map(item => item.collection),
    datasets: [
      {
        label: 'Spend',
        data: data.map(item => item.spend),
        backgroundColor: "green",
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        borderColor: "pink", 
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
        
        
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Spend (KES)',
          color: 'blue', // You can adjust the color if needed
          font: {
            size: 14, // You can adjust the font size if needed
            weight: 'bold', // You can adjust the font weight if needed
          },
        },
        callback: value => value.toLocaleString('en-US', { style: 'currency', currency: 'KES' }),
      },
    },
  };

  

  return (

    <>

<div className='container'>

<div className='row'>
  
    <div className="col-sm-12">
    <div style={{ width: '900px', height: '400px' }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
    </div>

</div>
</div> <br/>
      <div className="container">

      <div className="row">
  {data.slice(0, 3).map((item, index) => (
    <div key={index} className="col-sm-4 mb-6">
      <div
        className="card"
        style={{
          backgroundColor: "#083173",
          height: "100px", // Set the desired height explicitly
          display: "flex", // Ensure elements within the card fill the height
          alignItems: "center", // Vertically center the content
          justifyContent: "center", // Horizontally center the content
        }}
      >
        <div className="card-body-vivo">
          <h5 className="card-title" style={{ color: "#FFF !important;" }}>
            {item.collection}
          </h5>
          <p className="card-text-vivo">
            Spend:{" "}
            <strong>{parseFloat(item.spend).toLocaleString("en-US", { style: "currency", currency: "KES" })}</strong>
            
          </p>
        </div>
      </div>
    </div>
  ))}
</div>

  </div>

    </>

    
  
  
  );
};

export default OverallSpendChart;



//OverallSpendChart;
