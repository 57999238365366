import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Bar,Pie } from 'react-chartjs-2';



const ChartsInfo = () => {

    const [apiData, setApiData] = useState([]);

    const [totalSpentbyPartner, setTotalSpentByPartner] = useState([]);


    useEffect(() => {
      fetch('https://agra-failover-env2.vercel.app/api/data')
        .then(response => response.json())
        .then(data => setApiData(data.data))
        .catch(error => console.error('Error fetching data:', error));
    }, []);
  
    //show total budget utilized
    const calculateTotalBudget = () => {
        if (apiData.length > 0) {
          // apiData.forEach(( item )=>{
          //   console.log("Items is-->", item.attributes.attributes.Budget)
          // })
            const totalBudget = apiData.reduce((total, project) => total + parseFloat(project.attributes.Budget), 0);

            
            console.log('Total Budget log this--->:', totalBudget);
          } else {
            console.log('Total Budget: 0');
          }   
      };





    
      const countDistinctDevelopmentPartners = () => {
        const uniquePartners = [...new Set(apiData.map((project) => project.attributes.DevelopmentPartner))];
        return uniquePartners.length;
      };


      //Total Number Of Implementing Agencie
      
    
      // Chart.js data for total budget
      const totalBudgetData = {
        labels: ['Total Budget'],
        datasets: [
          {
            label: 'Total Budget',
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75,192,192,0.8)',
            hoverBorderColor: 'rgba(75,192,192,1)',
            data: [calculateTotalBudget()],
          },
        ],
      };


      const getDevelopmentPartnersData = () => {
        const developmentPartners = apiData.map((project) => project.attributes.DevelopmentPartner);
        const partnerCount = {};
        developmentPartners.forEach((partner) => {
          partnerCount[partner] = (partnerCount[partner] || 0) + 1;
        });
    
        const labels = Object.keys(partnerCount);
        const data = Object.values(partnerCount);
    
        return { labels, data };
      };



      //implementing Agencies
      const getimplementing_Agencies = () => {
        const implementingAgencies = apiData.map((project) => project.attributes.ImplementingAgency);
        const agencyCount = {};
        implementingAgencies.forEach((partner) => {
          agencyCount[partner] = (agencyCount[partner] || 0) + 1;
        });
    
        const labels = Object.keys(agencyCount);
        const data = Object.values(agencyCount);
    
        return { labels, data };
      };



      const developmentPartnersData = {
        labels: getDevelopmentPartnersData().labels,
        datasets: [
          {
            data: getDevelopmentPartnersData().data,
            backgroundColor: [
              'rgba(255,99,132,0.6)',
              'rgba(54,162,235,0.6)',
              'rgba(255,206,86,0.6)',
              'rgba(75,192,192,0.6)',
              'rgba(153,102,255,0.6)',
              'rgba(255,159,64,0.6)',
            ],
          },
        ],
      };

      const implementing_agencies = {

        labels: getimplementing_Agencies().labels,
        datasets: [
          {
            data: getimplementing_Agencies().data,
            backgroundColor: [
              'rgba(255,99,132,0.6)',
              'rgba(54,162,235,0.6)',
              'rgba(255,206,86,0.6)',
              'rgba(75,192,192,0.6)',
              'rgba(153,102,255,0.6)',
              'rgba(255,159,64,0.6)',
            ],
          },
        ],

      }



  return (
    
    <>
    <div className="row">
  <div className="col-md-10">
    {/* Wrap both cards in a container for consistent margins */}
    <div className="container">




      
      <div className="card card-danger mb-3" style={{ backgroundColor: '#086c14', padding: '10px', color: '#fff' }}>
        <Card.Body>
         
        <Card.Text>
  Total Programmes:{" "}
  <span style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
    {countDistinctDevelopmentPartners()}
  </span>
</Card.Text>





          {/* ...other card content */}
        </Card.Body>
      </div>

      
      <div className="card card-danger mb-3" >
        <Card.Body>
       
        <strong > <Card.Text>All Development Partners:</Card.Text> </strong>
          <Pie data={developmentPartnersData} />
        </Card.Body>
      </div>



      <div className="card card-danger mb-3">
        <Card.Body>
       
        <strong> <Card.Text>All Implementing Agencies:</Card.Text> </strong>
          <Pie data={implementing_agencies} />
        </Card.Body>
      </div>



     
    </div>
  </div>
  <div className=""></div>
</div>
    </>

  )
}

export default ChartsInfo

//beneficiaries