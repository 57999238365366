import React from 'react'
import '../assets/css/bootstrap.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../src/moa_logo.jpeg';
import SiteMenu from '../components/nav/SiteMenu';
import Hero from '../components/nav/Hero';
import Footer from './Footer';

const Home = () => {

    const logoStyle = {
        maxHeight: '3rem', // Adjust as needed
        marginRight: '1rem',
      };

  return (
    <>

    <SiteMenu/>
    <Hero/>
<Footer/>
    

  
  
  
  </>
  
  )
}

export default Home