import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from '../../../src/context/AuthContext';
import { signUpWithEmailAndPassword } from '../../../src/services/firebase';
import "../../../src/styles.css";

//1 Top toastify 
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteMenu from '../nav/SiteMenu';
import Footer from '../../views/Footer';

const Signup = () => {
  const navigate = useNavigate();
  //const { setUser } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Add additional validation logic as needed
    if (formData.password !== formData.confirmPassword) {
      // Handle password mismatch
      return;
    }

    try {
      // Call Firebase signup function
      const user = await signUpWithEmailAndPassword(formData.email, formData.password);

      toast.success('Successfully Create Account!', {
        position: 'top-right',
        className: 'custom-toast',
        autoClose: 3000, // Automatically close after 3 seconds
      });
     
      //navigate
      setTimeout(() => {
        navigate('/');
      }, 3000);

    } catch (error) {
      // Handle signup error (display error message or toast)
      console.error('Error Creating Account:', error);
    
      toast.error(`Error Creating Account: ${error.message}`, {
        position: 'top-right',
      });

      //console.error('Signup error:', error.message);
    }
  };

  return (

    <>
    <SiteMenu/> <br/>
    <section className="vh-100 d-flex align-items-center justify-content-center">
  <div className="container">
    <div className="row">
      <div className="col-md-6 offset-md-3 shadow-lg p-5 bg-white rounded">
        <div className="px-5 ms-xl-4">
          <i
            className="fas fa-crow fa-2x me-3 pt-5 mt-xl-4"
            style={{ color: "#709085" }}
          />
         
         

          <ToastContainer position="top-right" autoClose={3000} />
          
        </div>
        <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
          <form style={{ width: "100%" }}  onSubmit={handleSubmit}>
         
         
          <div className="mb-3">
            <label className="form-label" htmlFor="form2Example18">
                Email address
              </label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" required />
              
            </div>
            <div className="mb-3">
           
            <label className="form-label" htmlFor="form2Example28">
                Full Name
              </label>

            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} className="form-control" required />
             
            </div>
            <div className="mb-3">
            <label className="form-label" htmlFor="form2Example28">
               Phone Number
             </label>
            <input type="number" name="phone" value={formData.phone} onChange={handleChange} className="form-control" required />
            
           </div>

           <div className="mb-3">
           <label className="form-label" style={{ float: 'left' }} htmlFor="form3Example3">
Password
</label>
           <input type="password" name="password" value={formData.password} onChange={handleChange} className="form-control" required />
             
             
           </div>

           
 

           <div className="mb-3">
           <label className="form-label" style={{ float: 'left' }} htmlFor="form3Example3">
  Confirm Password
</label>
           <input type="password"  id="form3Example3" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} className="form-control" required />


           </div>




           <div className="mb-3">
              <button className="btn btn-success w-100" type="submit">
                Create Account
              </button>
            </div>
            <p className="small mb-5 pb-lg-2">
              <a className="text-muted" href="#!">
                Forgot password?
              </a>
            </p>
            <p>
              Have an existing account?{" "}
              <a href="/" className="link-info">
               Login here
              </a>
            </p>
          </form>
        </div>
      </div>
      <div className="col-sm-6 px-0 d-none d-sm-block">
   
   
      </div>
    </div>
  </div>
</section>



    
    </>
   
  );
};

export default Signup;
