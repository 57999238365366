
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../src/styles.css';
import { FaUser, FaChartBar, FaFileUpload, FaClipboardList, FaSignOutAlt } from 'react-icons/fa';
import NavMenu from '../components/nav/NavMenu';
import { collection, addDoc, serverTimestamp, getDocs, deleteDoc } from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';
import {  auth } from '../services/firebase';
import countiesData from "../data/Counties.json";
import * as XLSX from 'xlsx';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../src/services/firebase'; // Adjust the path accordingly
import Header from '../components/header/Header';
//../../../src/services/firebase
const ProducerAssociationsUploads = () => {

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      snapshot.docs.forEach((doc) => {
        const userData = doc.data();
        if (userData.uid === auth.currentUser.uid) {
          setIsAdmin(userData.adminStatus);
        }
      });
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);



  //console.log(countiesData);

  const [formData, setFormData] = useState({
    ProjectName: '',
    ProjectEnd: '',
    ProjectStart: '',
    ImplementingAgency: '',
    ProjectEnd: '',
    ThematicAreas: '',
    County: '',
    Budget: '',
    County:'',
    Category:'',
    Beneficiaries: '',
  });

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Get a reference to the Firestore collection
    const projectsCollection = collection(db, 'producer-associations');
    try {
      // Add data to Firestore
      await addDoc(projectsCollection, {
        ...formData,
        timestamp: serverTimestamp(), // Optional: Add a timestamp
      });

      // Reset the form after successful submission
      setFormData({
        ProjectName: '',
        ProjectEnd: '',
        ProjectStart: '',
        ImplementingAgency: '',
        ProjectEnd: '',
        ThematicAreas: '',
        County: '',
        Budget: '',
        County:'',
        Category:'',
        Beneficiaries: '',
      });
      
      toast.success('Data submitted successfully!', {
        //position: toast.POSITION.TOP_RIGHT,
        className: 'custom-toast',
        autoClose: 3000, // Automatically close after 3 seconds
      });
      console.log('Data submitted successfully!')

    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Error submitting data. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  //Upload Bulk Data in Firestore Collection
  const handleFileUpload = async (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (!file) {
      console.error('No file selected');
      return;
    }

    try {
      const arrayBuffer = await readFileAsArrayBuffer(file);
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      console.log("XLSDATA-->", jsonData)

      // Upload the JSON data to Firestore
      await uploadDataToFirestore(jsonData);

      console.log('Data uploaded successfully!');
    } catch (error) {
      console.error('Error reading file or uploading data:', error);
    }
  };

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

 
 
  const uploadDataToFirestore = async (jsonData) => {
    console.log("Trying to Upload data")
  
    const bulkDataCollection = collection(db, 'producer-associations');
  
    // Clear existing data in the collection (optional)
    const snapshot = await getDocs(bulkDataCollection);
    snapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  
    // Upload new data to Firestore
    jsonData.forEach(async (data) => {
      await addDoc(bulkDataCollection, data);
    });
  };
  
    //const bulkDataCollection = collection(db, 'bulkdata');

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };


  return (
    <>
 
 <Header/>


    <div className="container-fluid">
      <div className="row">
        {/* Navmenus */}
  
         {/* End Navmenus */}
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2" />   
            <div className="btn-toolbar mb-2 mb-md-0">
</div>
          </div>
          <div className='container'>
<div className='row'>
  <div className='col-sm-6'>
  <h5 style={{ textAlign: 'left' }}>Private Sector Data Upload</h5> <br/>

  <ToastContainer position="top-right" autoClose={3000} />

  <form onSubmit={handleSubmit}>


  <div className="form-group">
    <label htmlFor="DevelopmentPartner" className="text-left">Development Partner</label>
    <input type="text" className="form-control" id="DevelopmentPartner" placeholder="e.g World Bank" value={formData.DevelopmentPartner} onChange={handleChange} />
  </div>

  <div className="form-group">
    <label htmlFor="projectName" className="text-left">Project Name</label>
    <input type="text" className="form-control" id="projectName" placeholder="e.g Farmer Registration" value={formData.projectName} onChange={handleChange}/>
  </div>


  <div className="form-group">
    <label htmlFor="ThematicAreas" className="text-left">Thematic Areas</label>
    <input type="text" className="form-control" id="ThematicAreas" placeholder="" value={formData.ThematicAreas} onChange={handleChange} />
  </div>

  <div className="form-group">
    <label htmlFor="DevelopmentPartner" className="text-left">Category</label>
    <input type="text" className="form-control" id="Category" placeholder="" value={formData.Category} onChange={handleChange} />
  </div>

  <div className="form-group">
    <label htmlFor="Beneficiaries" className="text-left">Beneficiaries</label>
    <input type="text" className="form-control" id="Beneficiaries" placeholder="" value={formData.Beneficiaries} onChange={handleChange} />
  </div>

  <div className="form-group">
    <label htmlFor="DevelopmentPartner" className="text-left">Investment in USD</label>
    <input type="number" className="form-control" id="Budget" placeholder="" value={formData.Budget} onChange={handleChange} />
  </div>

  <div className="form-group">
    <label htmlFor="DevelopmentPartner" className="text-left">Implementing Agency</label>
    <input type="text" className="form-control" id="ImplementingAgency" placeholder="" value={formData.ImplementingAgency} onChange={handleChange} />
  </div>


  <div className="form-group">
    <label htmlFor="ProjectStart" className="text-left" >Startx Date</label>
    <input type="date" className="form-control" id="ProjectStart" value={formData.ProjectStart}
  onChange={handleChange} />
  </div>
  <div className="form-group">
    <label htmlFor="ProjectEnd" className="text-left">End Date</label>
    <input type="date" className="form-control" id="ProjectEnd" value={formData.ProjectEnd} onChange={handleChange}/>
  </div>

  <div className="form-group">
  <label htmlFor="County" className="text-left">Enter County</label>
  <select
    className="form-select"
    id="County"
    value={formData.County}
    onChange={handleChange}
  >
    {countiesData.counties.map((county) => (
      <option key={county.name} value={county.name}>
        {county.name}
      </option>
    ))}
  </select>
</div>

 <br/>



  <button type="submit" className="btn btn-success w-100">Submit Data</button>
</form>
  </div>


  <div className="col-sm-1">
      <div className="separator-line"></div>
    </div>


    <div className="col-sm-5">
      {isAdmin && (
        <div className="bulk-upload">
          <label className="form-label" htmlFor="customFile">
            Upload XLSX File
          </label>
          <input type="file" className="form-control" id="customFile" onChange={handleFileUpload} /> <br />
          <button type="submit" className="btn btn-success w-100">
            Bulk Upload Data
          </button>
        </div>
      )}
    </div>
</div>
</div>
        </main>
      </div>
    </div>
  </>
  )
}
export default ProducerAssociationsUploads






//ProducerAssociationsUploads