import React, { useState } from 'react'
import { useEffect } from 'react'


const Footer = () => {

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
    }, [])
    

  return (
<>


 
 
    <p style={{color:'#000', marginTop:'200px'}}>All Rights Reserved {currentYear}</p>







</>
  )
}

export default Footer