
import logo from './logo.svg';
import './App.css';
import Signup from "../src/components/auth/Signup";
import Login from "../src/components/auth/Login";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AdminPanel from './views/AdminPanel';
import UserDashboard from './views/UserDashboard';
import UserProfilePage from './views/UserProfilePage';
import UploadData from './views/UploadData';
import Analytics from './views/Analytics';
import Home from './views/Home';
import About from './views/About';
import Individual from './views/Individual';
import Systemic from './views/Systemic';
import Institutional from './views/Institutional';
import UnderConstruction from './views/UnderConstruction';
import DevelopmentAreas from './views/DevelopmentAreas';
import ProducerAssociations from './views/ProducerAssociations';
import GovOrganisations from './views/GovOrganisations';
import DevelopmentAreasUpload from './uploadsforms/DevelopmentAreasUpload';
import ProducerAssociationsUploads from './uploadsforms/ProducerAssociationsUploads';
import GovernmentAssociationsUploads from './uploadsforms/GovernmentAssociationsUploads';
import OverallSpend from './views/OverallSpend';
import StateDepartment from './views/StateDepartment';
import PrivateSector from './views/PrivateSector';

//add route -user-profile for approved users
function App() {
  return (
    <div className="App">

<BrowserRouter>



  <div className='page-body'>
   
    <Routes>
      <Route path='/signup' element={<Signup />} />
      <Route path='/login' element={<Login />} />
      <Route path='/' element={<Home />} />
     
      <Route path='/about' element={<About />} />
      <Route path='/a/admin' element={<AdminPanel />} />
      <Route path='/user-dashboard' element={<UserDashboard />} />
      <Route path='/overall-spend' element={<OverallSpend />} />

      <Route path='/state-departments' element={<StateDepartment />} />

      <Route path='/user-profile' element={<UserProfilePage />} />
      <Route path='/bulk-upload' element={<UploadData />} />
      <Route path='/development-agencies' element={<DevelopmentAreas />} />
      <Route path='/development-areas-upload' element={<DevelopmentAreasUpload />} />
      <Route path='/private-sector-upload' element={<ProducerAssociationsUploads />} />

      <Route path='/government-agencies-upload' element={<GovernmentAssociationsUploads />} />

      <Route path='/producer-associations' element={<ProducerAssociations />} />

      <Route path='/private-sector' element={<PrivateSector />} />
      <Route path='/goverment-organisations' element={<GovOrganisations />} />

      <Route path='/individual' element={<Individual />} />
      <Route path='/systemic' element={<Systemic />} />
      <Route path='/institutional' element={<Institutional />} />

      <Route path='/analytics' element={<Analytics />} />


      
    </Routes>
  </div>
</BrowserRouter>
      
      
    </div>
  );
}

export default App;