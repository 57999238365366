import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { onSnapshot, collection, getDoc, doc } from 'firebase/firestore';
import { firebaseSignOut, auth, db } from '../../services/firebase';
import { FaUser, FaChartBar, FaSignOutAlt } from 'react-icons/fa';
import { Button, Dropdown } from 'react-bootstrap';
import { PersonCircle, BoxArrowRight, Person } from 'react-bootstrap-icons';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import "../../../src/App.css"

import logo from '../../../src/moa_logo.jpeg';
import  '../../../src/App.css'
const Header = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState(null); // Change to userEmail
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState();
  const navLinkStyle = {
    padding: '0.5rem 1rem', // Add padding between Nav.Link items
    borderRadius: '5px', // Add rounded corners
    transition: 'background-color 0.3s ease', // Add smooth transition for hover effect
    marginRight: '1rem',
    lineHeight: '1.5',
    fontWeight: '500',
    fontSize: '16px',
    color: '#333',
    textDecoration: 'none',
    transition: 'color 0.2s ease-in-out',
  };


  useEffect(() => {
    
    const user_email = localStorage.getItem('email')

    if (user_email) {
      const logged_name = user_email.split('@')[0];
      setUserEmail(logged_name)
      //console.log("User Email is ", user_email);
    } else {
      //console.log("Failed to get email");
    }
  
  }, [])
  


  

  const handleLogout = async () => {
    try {
      await firebaseSignOut();
      navigate('/');
    } catch (error) {
      // Handle errors
    }
  };

  // Inline styling for a more modern look
  const navMenuStyle = {
    display: 'flex',
    justifyContent: 'space-between', // Adjust alignment as needed
    alignItems: 'center',
    padding: '0.5rem 1rem',
    backgroundColor: '#f5f5f5',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  };


  

  const navLinkActiveStyle = {
    ...navLinkStyle,
    color: '#007bff', // Use Bootstrap theme color or your preference
  };

  const logoStyle = {
    maxHeight: '3rem', // Adjust as needed
  
  };

  // ... rest of your Header component code
  const [activeKey, setActiveKey] = useState('/home');
  const activeStyle = {
    fontWeight: 'bold',
    backgroundColor: '#000',
    color:'#FFF' // Add a green box background for the active state
  };



  return (

    <>
 


 <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/" style={{ marginRight: '30px' }}>
        <img src={logo} alt="Logo" style={logoStyle} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
         
         


          <Nav.Link href="/overall-spend" >
            Overall
          </Nav.Link>

          <NavDropdown title="Government" id="programmes-dropdown">
            <NavDropdown.Item href="/state-departments">State Departments</NavDropdown.Item>
            <NavDropdown.Item href="/goverment-organisations">Government Agencies</NavDropdown.Item>
          
            {/* Add more items as needed */}
          </NavDropdown>

          <Nav.Link href="/analytics">
            Development Partners
          </Nav.Link>

     

     

          

         
         
          <Nav.Link href="/private-sector">
          Private Sector
          </Nav.Link>


          <NavDropdown title="Upload Data" id="categories-dropdown">
           
          <NavDropdown.Item href="/government-agencies-upload">Government</NavDropdown.Item>
            <NavDropdown.Item href="/development-areas-upload">Development Partners</NavDropdown.Item>
            <NavDropdown.Item href="/private-sector-upload">Private Sector </NavDropdown.Item>
            
            {/* Add more items as needed */}
          </NavDropdown>

          <Nav.Link href="/" >
          <PersonCircle size={24} className="mr-2" /> Sign Out
          </Nav.Link>

        </Nav>
      </Navbar.Collapse>
    </Navbar>

  <p style={{textAlign:"left", paddingLeft:'35px'}}>    <PersonCircle size={24} className="mr-2" />  <strong> Welcome, {userEmail} </strong></p>
    </>

  
  );
};

export default Header;


//Upload Flag for non -Admins
//Spelling on Development Partners
//Inputs appearing more than once
// Data to appear on graph
//add commas -done
//all total
//Spend - done
//Beneficiaries
//commas
//All changes to cut across, systemic, individual, institutional - done
//logout -done
//Go to Home page - done
//Sign Out - done
//beneficiaries - done
//logic to upload data - done
//Total All and For Beneficiries - pending

{/* <NavDropdown title="Programme Categories" id="categories-dropdown">
<NavDropdown.Item href="/individual">Individual</NavDropdown.Item>
<NavDropdown.Item href="/institutional">Institutional</NavDropdown.Item>
<NavDropdown.Item href="/systemic">Systemic</NavDropdown.Item>
</NavDropdown> */}


//Overall Programmes
//2 Development Partners- Overall 