import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import banner from '../../../src/assets/images/icsp.jpg';

const Hero = () => {
  return (
    <>
    <div></div> <br/>


<div class="container">
  <div class="row">
    <div class="col-md-7 p-4 p-md-5">
     
    <h3 class="" style={{textAlign:'left'}}>
    Agricultural Sector Institutional Capacity Strengthening Plan (ICSP)
</h3>
<p class="lead" style={{textAlign:'left'}}>
ICSP is building on the Agriculture Sector Transformation and Growth strategy and
foundation to provide a five-year framework for capacity development in the sector. The
ICSP addresses all the systemic capacity challenges hindering agricultural sector
development and transformation
</p>


      <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-md-3">
       

        <a href='/login' className='btn btn-success btn-lg px-4 me-md-2'>  Dashboard </a>
      
      
      </div>
    </div>
    <div class="col-md-5 p-0 overflow-hidden shadow-lg">
    <img src={banner} alt="Logo"  />
    </div>
  </div>
</div>



    
    </>
  )
}

export default Hero