
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../src/styles.css';
import { FaUser, FaChartBar, FaFileUpload, FaClipboardList, FaSignOutAlt } from 'react-icons/fa';
import NavMenu from '../components/nav/NavMenu';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import ProjectsChart from '../components/analytics/ProjectsChart';
import countiesData from "../data/Counties.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../src/services/firebase';
import Header from '../components/header/Header';
import ChartsInfo from '../components/analytics/ChartsInfo';
import PrivateSectorChart from '../components/analytics/PrivateSectorChart';

const PrivateSector = () => {
  const [formData, setFormData] = useState({
    projectCounty: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
    
    <Header/>

      <div className="container-fluid">
        <div className="row">
          {/* Navmenus */}
         
         
          {/* End Navmenus */}
          <main className="">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-left pt-3 pb-2 mb-3 border-bottom">
             
         
         
             
            </div>

            <div className='container'>
              <div className='row'> 
           
           

                <div className='col-sm-10'>
                  <h5 style={{ textAlign: 'center' }}>Overall Private Sector Spend</h5> <br /> 
                  <PrivateSectorChart/>
                </div>

              
              

               
               
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default PrivateSector;



//PrivateSector