
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../src/styles.css';
import { FaUser, FaChartBar, FaFileUpload, FaClipboardList, FaSignOutAlt } from 'react-icons/fa';
import { collection, getDocs, query, where, updateDoc, doc, deleteDoc } from 'firebase/firestore';
//import { getAuth, listUsers } from '../../../src/services/firebase';
import { db } from '../../src/services/firebase';
import { getAuth, deleteUser as deleteFirebaseUser  } from 'firebase/auth';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavMenu from '../components/nav/NavMenu';
import Header from '../components/header/Header';
//import { uid } from 'chart.js/dist/helpers/helpers.core';

const AdminPanel = () => {

  const [users, setUsers] = useState([]);


  //hook to navigate
  const navigate = useNavigate()


  //handle Approve user
  const handleApprove = async (uid) => {
    try {
      console.log('uid is', uid);
      // Create a query to find the user by uid
      const userQuery = query(collection(db, 'users'), where('uid', '==', uid));
      // Execute the query
      const querySnapshot = await getDocs(userQuery);
      // Check if any documents were found
      if (!querySnapshot.empty) {
        // Update the user status to 'approved'
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document with a given uid
        await updateDoc(userDoc.ref, {
          status: 'approved',
        });


        toast.success('Success: User Approved', {
          position: 'top-right',
          className: 'custom-toast',
          autoClose: 3000, // Automatically close after 3 seconds
        });
       
        //navigate
        setTimeout(() => {
          navigate('/');
        }, 3000);

        console.log('Success: User Approved');
        //reload the page
        window.location.reload();
        //refresh page
       
        //improvments would be to auto-refresh page and change a badge
      } else {
        console.error('Error: User not found');
      }
    } catch (error) {
      console.error('Error approving user:', error.message);
    }
  };

  //delete user from the system
  const handleDelete = async (uid) => {
    try {
      console.log('Trying to Delete: This User', uid);
       // Create a query to find the user by UID
      const userQuery = query(collection(db, 'users'), where('uid', '==', uid));
      // Get the user document based on the query
      const querySnapshot = await getDocs(userQuery);
      // Check if the user exists
      if (querySnapshot.size === 1) {
        // Get the reference to the user document
        const userDocRef = querySnapshot.docs[0].ref;

     
  
        // Delete the user document
        await deleteDoc(userDocRef);
  
        console.log('User Approval Declined');

        //reload the page
        window.location.reload();


      } else {
        console.log('Error: User not found');
      }
    } catch (error) {
      console.error('Error deleting user:', error.message);
    }
  };


  // const handleDelete = async (uid) => {
  //   try {
  //     console.log('Trying to Delete: This User', uid);
  
  //     // Create a query to find the user by UID
  //     const userQuery = query(collection(db, 'users'), where('uid', '==', uid));
      
  //     // Get the user document based on the query
  //     const querySnapshot = await getDocs(userQuery);
  
  //     // Check if the user exists
  //     if (querySnapshot.size === 1) {
  //       // Get the reference to the user document
  //       const userDocRef = querySnapshot.docs[0].ref;
  
  //       // Get the user data from Firestore
  //       const userData = querySnapshot.docs[0].data();
  
  //       // Delete the user document
  //       await deleteDoc(userDocRef);
  
  //       console.log('Success: User Deleted from Firestore');
  
  //       // Delete the user from Firebase Authentication
  //       const auth = getAuth();
  //       await deleteFirebaseUser(auth.currentUser);
  
  //       console.log('Success: User Deleted from Firebase Authentication');
  
  //       // Reload the page
  //       window.location.reload();
  //     } else {
  //       console.log('Error: User not found');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting user:', error.message);
  //   }
  // };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const usersData = [];
        querySnapshot.forEach((doc) => {
          usersData.push(doc.data());
        });
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
   
<Header/>
      <div className="container-fluid">
        <div className="row">
          {/* Navmenus */}


           {/* End Navmenus */}
         

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2" />
              <div className="btn-toolbar mb-2 mb-md-0"></div>
            </div>
            <h5 style={{ textAlign: 'left' }}>Users</h5> <br/>

            <ToastContainer position="top-right" autoClose={3000} />
            
            <div className="table-responsive small">
              <table className="table table-striped table-sm">
              <thead>
          <tr>
            <th>Email</th>
            <th>User Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.uid}>
              <td>{user.email}</td>
              <td>{user.adminStatus ? 'Admin' : 'Normal User'}</td>
              <td>{user.status}</td>
             
              <td style={{ margin: '10px' }}>
  <button className='btn btn-success' onClick={() => handleApprove(user.uid)}>Approve</button> 
  <button className='btn btn-danger' onClick={() => handleDelete(user.uid)} >Decline</button>
</td>

            </tr>
          ))}
        </tbody>
                
              </table>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
