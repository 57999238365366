import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../src/styles.css";
import {
  FaUser,
  FaChartBar,
  FaFileUpload,
  FaClipboardList,
  FaSignOutAlt,
} from "react-icons/fa";
import NavMenu from "../components/nav/NavMenu";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import ProjectsChart from "../components/analytics/ProjectsChart";
import countiesData from "../data/Counties.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../src/services/firebase";
import Header from "../components/header/Header";
import ChartsInfo from "../components/analytics/ChartsInfo";
import OverallSpendChart from "../components/analytics/OverallSpendChart";

const OverallSpend = () => {
  const [formData, setFormData] = useState({
    projectCounty: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <Header />
  
      <div className="container-fluid">
       
        <div className="row">
          {/* Navmenus */}
          
          {/* End Navmenus */}
          <main className="">
         
         
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h5>Overall Spend</h5>
                  <OverallSpendChart />
                </div>

                <div className="col-sm-8">
                 
                 
                </div>

               
               
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default OverallSpend;

//OverallSpend
